import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Config
import { TIME_FORMATS } from '../../../../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../../generics/Details';
import LabelField from '../../../generics/LabelField';
import LabelValuePair from '../../../generics/LabelValuePair';
import SubHeaderModule from '../../../generics/SubHeaderModule';

// Util(s)
import { toEventTime } from '../../../../../../../../TaskList/air/helper/toEventDate';
import ItineraryUtil from '../../../../../../../../../../utils/Journey/Uplift/itineraryUtil';
import JourneyUtil from '../../../../../../../../../../utils/Journey/Uplift/journeyUtil';

const Itinerary = ({ itinerary }) => {
  const LABELS = {
    DEPARTS: 'Departs',
    ARRIVES: 'Arrives',
    FLIGHT_NUMBER: 'Flight no.',
    ROUTE: 'Route',
  };

  if (!itinerary || !itinerary?.length) {
    return null;
  }

  return (
    <div id="itinerary-module">
      <SubHeaderModule
        id="itinerary-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="itinerary-header" size="s">Itinerary</Heading>
              </div>
            ),
          },
        ]}
      />
      {itinerary.map((leg, index) => {
        const departureLocation = JourneyUtil.departureLoc(leg);
        const departureTime = JourneyUtil.departureTime(leg);
        const arrivalLocation = JourneyUtil.arrivalLoc(leg);
        const arrivalTime = JourneyUtil.arrivalTime(leg);
        const flightNumber = ItineraryUtil.flightNumber(leg);

        return (
          <Details
            key={flightNumber}
            items={[
              {
                content: (
                  <ComponentWrapper key="1" show={departureTime && departureLocation}>
                    <div className="grid-item">
                      <ComponentWrapper show={index}>
                        <LabelField
                          classNames="govuk-body-s font--grey mobile-label"
                          id={`itinerary-departure-${flightNumber}-label`}
                          value={LABELS.DEPARTS}
                        />
                      </ComponentWrapper>
                      <LabelValuePair
                        id={`itinerary-departure-${flightNumber}`}
                        label={!index && LABELS.DEPARTS}
                        value={(
                          <>
                            <span>{toEventTime(departureTime, departureLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                            <span>{toEventTime(departureTime, departureLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                          </>
                        )}
                      />
                    </div>
                  </ComponentWrapper>
                ),
              },
              {
                content: (
                  <ComponentWrapper key="2" show={arrivalTime && arrivalLocation}>
                    <div className="grid-item">
                      <ComponentWrapper show={index}>
                        <LabelField
                          classNames="govuk-body-s font--grey mobile-label"
                          id={`itinerary-arrival-${flightNumber}-label`}
                          value={LABELS.ARRIVES}
                        />
                      </ComponentWrapper>
                      <LabelValuePair
                        id={`itinerary-arrival-${flightNumber}`}
                        label={!index && LABELS.ARRIVES}
                        value={(
                          <>
                            <span>{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                            <span>{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                          </>
                        )}
                      />
                    </div>
                  </ComponentWrapper>
                ),
              },
              {
                content: (
                  <ComponentWrapper key="3" show={flightNumber}>
                    <div className="grid-item">
                      <ComponentWrapper show={index}>
                        <LabelField
                          classNames="govuk-body-s font--grey mobile-label"
                          id={`itinerary-flight-number-${flightNumber}-label`}
                          value={LABELS.FLIGHT_NUMBER}
                        />
                      </ComponentWrapper>
                      <LabelValuePair id={`itinerary-flight-number-${flightNumber}`} label={!index && LABELS.FLIGHT_NUMBER} value={flightNumber} />
                    </div>
                  </ComponentWrapper>
                ),
              },
              {
                content: (
                  <ComponentWrapper key="4" show={departureLocation && arrivalLocation}>
                    <div className="grid-item">
                      <ComponentWrapper show={index}>
                        <LabelField
                          classNames="govuk-body-s font--grey mobile-label"
                          id={`itinerary-flight-route-${flightNumber}-label`}
                          value={LABELS.ROUTE}
                        />
                      </ComponentWrapper>
                      <LabelValuePair
                        id={`itinerary-flight-route-${flightNumber}`}
                        label={!index && LABELS.ROUTE}
                        value={(
                          <>
                            <span id={`itinerary-departure-location-${flightNumber}`}>{departureLocation}</span>&nbsp;
                            <span className="right-arrow">&#8594;</span>&nbsp;
                            <span id={`itinerary-arrival-location-${flightNumber}`}>{arrivalLocation}</span>&nbsp;
                          </>
                        )}
                      />
                    </div>
                  </ComponentWrapper>
                ),
              },
            ]}
          />
        );
      })}
    </div>
  );
};

Itinerary.propTypes = {
  itinerary: PropTypes.arrayOf(PropTypes.shape({})),
};

Itinerary.defaultProps = {
  itinerary: [],
};

export default Itinerary;
