// Config(s)
import config from '../../../../utils/config';
import { FILTER_TAB_ID, LOCAL_STORAGE_KEYS, MOVEMENT_MODES, PATHS, RBAC, STRINGS, TAB_ID, TASK_STATUS, TASK_MODES, VIEW } from '../../../../utils/constants';

export const AIRPAX_MODE_PARAMS = {
  taskStatuses: [],
  mode: MOVEMENT_MODES.AIR_PASSENGER,
  movementModes: [MOVEMENT_MODES.AIR_PASSENGER],
  selectors: 'ANY',
  rules: [],
  searchText: null,
  assignees: [],
  journeyDirections: [],
  passengerStatuses: [],
  targetingTeams: [],
  event: {
    inbound: { startDate: null, endDate: null },
    outbound: { startDate: null, endDate: null },
  },
  createdDate: {},
  arrivalDate: { value: 'IS', label: 'is' },
  departureDate: { value: 'IS', label: 'is' },
  journeyId: null,
  selectedForDismissalBy: [],
  issuedBy: [],
};

export const GENERAL_AVIATION_MODE_PARAMS = {
  taskStatuses: [],
  mode: MOVEMENT_MODES.GENERAL_AVIATION,
  movementModes: [MOVEMENT_MODES.GENERAL_AVIATION],
  selectors: null,
  rules: [],
  searchText: null,
  assignees: [],
  journeyDirections: [],
  passengerStatuses: [],
  targetingTeams: [],
  event: {
    inbound: { startDate: null, endDate: null },
    outbound: { startDate: null, endDate: null },
  },
  createdDate: {},
  arrivalDate: { value: 'IS', label: 'is' },
  departureDate: { value: 'IS', label: 'is' },
  journeyId: null,
  selectedForDismissalBy: [],
  issuedBy: [],
};

export const RORO_MODE_PARAMS = {
  movementModes: [MOVEMENT_MODES.ACCOMPANIED_FREIGHT, MOVEMENT_MODES.UNACCOMPANIED_FREIGHT, MOVEMENT_MODES.TOURIST],
  mode: [],
  selectors: 'ANY',
  rules: [],
  searchText: null,
  assignees: [],
  journeyDirections: [],
  selectedForDismissalBy: [],
  targetingTeams: [],
  createdDate: {},
  event: {
    inbound: { startDate: null, endDate: null },
  },
  passengerStatuses: [],
  journeyId: null,
  arrivalDate: { value: 'IS', label: 'is' },
  issuedBy: [],
};

export const FILTER_TYPES = {
  SELECTORS: 'selectors',
  JOURNEY_DIRECTIONS: 'journeyDirections',
  PASSENGER_STATUSES: 'passengerStatuses',
  RULES: 'rules',
  TARGETING_TEAMS: 'targetingTeams',
  MODE: 'mode',
};

export const JOURNEY_DIRECTIONS = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
  UNKNOWN: 'UNKNOWN',
};

export const SELECTORS = {
  ANY: 'ANY',
  PRESENT: 'PRESENT',
  NOT_PRESENT: 'NOT_PRESENT',
};

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DISMISS_REASON_DESCRIPTIONS = {
  ARRIVED: 'Arrived at port',
  FALSE_MATCH: 'False match',
  RESOURCE_REDIRECTED: 'Resource redirected',
  OTHER: null,
};

export const TASKS_TABS = [
  {
    id: TAB_ID.NEW,
    label: 'New',
    heading: 'New tasks',
    taskStatus: TASK_STATUS.NEW,
  },
  {
    id: TAB_ID.SELECTED_FOR_DISMISSAL,
    label: 'To dismiss',
    heading: 'Dismiss tasks',
    taskStatus: TASK_STATUS.SELECTED_FOR_DISMISSAL,
  },
  {
    id: TAB_ID.IN_PROGRESS,
    label: 'In progress',
    heading: 'In progress tasks',
    taskStatus: TASK_STATUS.IN_PROGRESS,
  },
  {
    id: TAB_ID.ISSUED,
    label: 'Issued',
    heading: 'Target issued tasks',
    taskStatus: TASK_STATUS.ISSUED,
  },
  {
    id: TAB_ID.RECEIVED,
    label: 'Received',
    heading: 'Target received tasks',
    taskStatus: TASK_STATUS.RECEIVED,
  },
  {
    id: TAB_ID.COMPLETE,
    label: 'Completed',
    heading: 'Completed tasks',
    taskStatus: TASK_STATUS.COMPLETE,
  },
];

export const FILTER_TABS = [
  {
    id: FILTER_TAB_ID.FILTERS,
    label: 'Filters',
  },
  {
    id: FILTER_TAB_ID.TOOLS,
    label: 'Tools',
  },
];

const AIRPAX_DEFAULTS = {
  filters: {
    key: LOCAL_STORAGE_KEYS.AIRPAX_FILTERS,
    default: AIRPAX_MODE_PARAMS,
    passengerStatuses: [
      {
        ...AIRPAX_MODE_PARAMS,
        passengerStatuses: ['BOOKED_PASSENGER'],
      },
      {
        ...AIRPAX_MODE_PARAMS,
        passengerStatuses: ['CHECKED_IN'],
      },
      {
        ...AIRPAX_MODE_PARAMS,
        passengerStatuses: ['DEPARTURE_CONFIRMED'],
      },
      {
        ...AIRPAX_MODE_PARAMS,
        passengerStatuses: ['DEPARTURE_EXCEPTION'],
      },
    ],
    selectors: [
      {
        ...AIRPAX_MODE_PARAMS,
        selectors: SELECTORS.PRESENT,
      },
      {
        ...AIRPAX_MODE_PARAMS,
        selectors: SELECTORS.NOT_PRESENT,
      },
      {
        ...AIRPAX_MODE_PARAMS,
      },
    ],
    directions: [
      {
        ...AIRPAX_MODE_PARAMS,
        journeyDirections: [JOURNEY_DIRECTIONS.INBOUND],
      },
      {
        ...AIRPAX_MODE_PARAMS,
        journeyDirections: [JOURNEY_DIRECTIONS.OUTBOUND],
      },
      {
        ...AIRPAX_MODE_PARAMS,
        journeyDirections: [JOURNEY_DIRECTIONS.UNKNOWN],
      },
    ],
    targetingTeams: [],
    rules: [],
  },
  sortParams: [
    {
      field: 'WINDOW_OF_OPPORTUNITY',
      order: SORT_ORDER.ASC,
    },
    {
      field: 'BOOKING_LEAD_TIME',
      order: SORT_ORDER.ASC,
    },
  ],
  maxResults: 30,
  tabs: TASKS_TABS,
  taskModes: [],
};

const DEFAULTS = {
  [VIEW.AIRPAX]: {
    ...AIRPAX_DEFAULTS,
    headers: {
      title: `Task management (${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.AIRPAX})`,
      links: [
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.AIRPAX_UPLIFT,
          show: config.upliftUIEnabled,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_READ,
          },
          view: VIEW.AIRPAX_V2,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.AIRPAX,
    rbac: {
      readRole: RBAC.TASK_AIRPAX_READ,
    },
  },
  [VIEW.AIRPAX_V2]: {
    ...AIRPAX_DEFAULTS,
    headers: {
      title: `Task management (${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.AIRPAX})`,
      links: [
        {
          url: PATHS.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.AIRPAX,
          show: config.legacyAirpaxTaskListEnabled,
          rbac: {
            readRole: [RBAC.TASK_AIRPAX_READ],
          },
          view: VIEW.AIRPAX,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.UPLIFT.AIRPAX,
    rbac: {
      readRole: RBAC.TASK_AIRPAX_READ,
    },
  },
  [VIEW.COMMODITIES]: {
    ...AIRPAX_DEFAULTS,
    headers: {
      title: `${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.COMMODITIES}`,
      links: [
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.CTBP,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_CTBP_READ,
          },
          view: VIEW.CTBP,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.IDP,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_IDP_READ,
          },
          view: VIEW.IDP,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.OSDT,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_OSDT_READ,
          },
          view: VIEW.OSDT,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.UPLIFT.AIRPAX,
    rbac: {
      readRole: RBAC.TASK_AIRPAX_COMMODITIES_READ,
    },
    taskModes: TASK_MODES.COMMODITIES_TASK,
  },
  [VIEW.CTBP]: {
    ...AIRPAX_DEFAULTS,
    headers: {
      title: `${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.CTBP}`,
      links: [
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.COMMODITIES,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_COMMODITIES_READ,
          },
          view: VIEW.COMMODITIES,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.IDP,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_IDP_READ,
          },
          view: VIEW.IDP,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.OSDT,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_OSDT_READ,
          },
          view: VIEW.OSDT,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.UPLIFT.AIRPAX,
    rbac: {
      readRole: RBAC.TASK_AIRPAX_CTBP_READ,
    },
    taskModes: TASK_MODES.CTBP_TASK,
  },
  [VIEW.IDP]: {
    ...AIRPAX_DEFAULTS,
    headers: {
      title: `${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.IDP}`,
      links: [
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.CTBP,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_CTBP_READ,
          },
          view: VIEW.CTBP,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.COMMODITIES,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_COMMODITIES_READ,
          },
          view: VIEW.COMMODITIES,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.OSDT,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_OSDT_READ,
          },
          view: VIEW.OSDT,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.UPLIFT.AIRPAX,
    rbac: {
      readRole: RBAC.TASK_AIRPAX_IDP_READ,
    },
    taskModes: TASK_MODES.IDP_TASK,
  },
  [VIEW.OSDT]: {
    ...AIRPAX_DEFAULTS,
    headers: {
      title: `${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.OSDT}`,
      links: [
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.CTBP,
          show: true,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_CTBP_READ,
          },
          view: VIEW.CTBP,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.UPLIFT.AIRPAX,
    rbac: {
      readRole: RBAC.TARGETER_AIRPAX_OSDT,
    },
    taskModes: TASK_MODES.OSDT_TASK,
  },
  [VIEW.GENERAL_AVIATION]: {
    filters: {
      key: LOCAL_STORAGE_KEYS.GENERAL_AVIATION_FILTERS,
      default: GENERAL_AVIATION_MODE_PARAMS,
      directions: [
        {
          ...GENERAL_AVIATION_MODE_PARAMS,
          journeyDirections: [JOURNEY_DIRECTIONS.INBOUND],
        },
        {
          ...GENERAL_AVIATION_MODE_PARAMS,
          journeyDirections: [JOURNEY_DIRECTIONS.OUTBOUND],
        },
      ],
    },
    headers: {
      title: 'Task management : GAMAH',
      links: [
        {
          url: PATHS.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.AIRPAX,
          show: config.legacyAirpaxTaskListEnabled,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_READ,
          },
          view: VIEW.AIRPAX,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: config.legacyAirpaxTaskListEnabled ? STRINGS.TASK_LINK_HEADERS.COMMODITIES : STRINGS.TASK_LINK_HEADERS.AIRPAX,
          show: config.upliftUIEnabled,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_READ,
          },
          view: VIEW.COMMODITIES,
        },
        {
          url: PATHS.RORO,
          label: STRINGS.TASK_LINK_HEADERS.RORO,
          show: true,
          rbac: {
            readRole: RBAC.TASK_RORO_READ,
          },
          view: VIEW.RORO,
        },
      ],
    },
    redirectPath: PATHS.GENERAL_AVIATION,
    sortParams: [
      {
        field: 'WINDOW_OF_OPPORTUNITY',
        order: SORT_ORDER.ASC,
      },
    ],
    tabs: TASKS_TABS,
    maxResults: 30,
    rbac: {
      readRole: RBAC.TASK_GA_READ,
    },
    taskModes: TASK_MODES.GA_TASK,
  },
  [VIEW.RORO]: {
    filters: {
      key: LOCAL_STORAGE_KEYS.RORO_FILTERS,
      default: RORO_MODE_PARAMS,
      movementModes: [
        {
          ...RORO_MODE_PARAMS,
          movementModes: [MOVEMENT_MODES.UNACCOMPANIED_FREIGHT],
        },
        {
          ...RORO_MODE_PARAMS,
          movementModes: [MOVEMENT_MODES.ACCOMPANIED_FREIGHT],
        },
        {
          ...RORO_MODE_PARAMS,
          movementModes: [MOVEMENT_MODES.TOURIST],
        },
      ],
      selectors: [
        {
          ...RORO_MODE_PARAMS,
          selectors: SELECTORS.PRESENT,
        },
        {
          ...RORO_MODE_PARAMS,
          selectors: SELECTORS.NOT_PRESENT,
        },
        {
          ...RORO_MODE_PARAMS,
        },
      ],
      directions: [
        {
          ...RORO_MODE_PARAMS,
          journeyDirections: [JOURNEY_DIRECTIONS.INBOUND],
        },
        {
          ...RORO_MODE_PARAMS,
          journeyDirections: [JOURNEY_DIRECTIONS.OUTBOUND],
        },
        {
          ...RORO_MODE_PARAMS,
          journeyDirections: [JOURNEY_DIRECTIONS.UNKNOWN],
        },
      ],
    },
    headers: {
      title: `Task management (${STRINGS.TASK_MANAGEMENT_INLINE_HEADERS.RORO})`,
      links: [
        {
          url: PATHS.AIRPAX,
          label: STRINGS.TASK_LINK_HEADERS.AIRPAX,
          show: config.legacyAirpaxTaskListEnabled,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_READ,
          },
          view: VIEW.AIRPAX,
        },
        {
          url: PATHS.UPLIFT.AIRPAX,
          label: config.legacyAirpaxTaskListEnabled ? STRINGS.TASK_LINK_HEADERS.AIRPAX_UPLIFT : STRINGS.TASK_LINK_HEADERS.AIRPAX,
          show: config.upliftUIEnabled,
          rbac: {
            readRole: RBAC.TASK_AIRPAX_READ,
          },
          view: VIEW.COMMODITIES,
        },
        {
          url: PATHS.GENERAL_AVIATION,
          label: STRINGS.TASK_LINK_HEADERS.GENERAL_AVIATION,
          show: config.generalAviationUIEnabled,
          rbac: {
            readRole: RBAC.TASK_GA_READ,
          },
          view: VIEW.GENERAL_AVIATION,
        },
      ],
    },
    redirectPath: PATHS.RORO,
    sortParams: [
      {
        field: 'EVENT_TIME',
        order: SORT_ORDER.ASC,
      },
      {
        field: 'THREAT_LEVEL',
        order: SORT_ORDER.DESC,
      },
    ],
    tabs: TASKS_TABS,
    maxResults: 50,
    rbac: {
      readRole: RBAC.TASK_RORO_READ,
    },
    taskModes: TASK_MODES.RORO_TASK,
  },
};

export const FILTER_TAB_SELECTION = 0;

export default DEFAULTS;
